/** @jsxImportSource @emotion/react */
import { css, useTheme, Theme } from '@emotion/react';
import { FC } from 'react';

import { Box, Flex } from 'components/styled';
import { Wrapper, ResponsivePadder } from 'components/styled/Wrapper';
import Text from 'components/styled/Text';
import Link, { HrefLink } from 'components/styled/Link';

import { ReactComponent as PhoneIcon } from 'assets/zlin/phone.svg';
import { ReactComponent as MailIcon } from 'assets/zlin/mail.svg';
import { ReactComponent as ClockIcon } from 'assets/zlin/clock.svg';

import { useLocalizedMultistring } from 'utils/localization';

const ZlinHeader: FC = () => {
	const localize = useLocalizedMultistring();
	const { colors } = useTheme();
	return (
		<Wrapper py={2} bg="lightGrey" color="secondary">
			<ResponsivePadder
				justifyContent="space-between"
				alignItems="center"
				width={1}
				flexDirection="row"
			>
				<Flex>
					<Text>
						{localize({
							CZECH:
								'Registruj se na on-line veletrh pracovních příležitostí na',
							ENGLISH: 'Register for an online job fair at',
						})}{' '}
						<HrefLink
							color="primary"
							href="https://businessdays.utb.cz/"
							target="_blank"
						>
							www.businessday.utb.cz
						</HrefLink>{' '}
						{localize({ CZECH: 'zde.', ENGLISH: '.' })}
					</Text>
				</Flex>
				<Flex
					justifyContent="flex-end"
					alignItems="center"
					gap={4}
					fontSize="sm"
					css={(theme: Theme) => css`
						@media (max-width: ${theme.breakpoints[3]}) {
							display: none;
						}
					`}
				>
					<Box as="span">
						<PhoneIcon fill={colors.primary} />
						<HrefLink color="secondary" ml={3} href="tel:+420576032021">
							+420 576 032 021
						</HrefLink>
					</Box>
					<Box as="span">
						<MailIcon fill={colors.primary} />
						<HrefLink color="secondary" ml={3} href="mailto:jobcentrum@utb.cz">
							jobcentrum@utb.cz
						</HrefLink>
					</Box>
					<Box as="span">
						<ClockIcon fill={colors.primary} />
						<HrefLink
							color="secondary"
							ml={3}
							css={css`
								&:hover,
								&:focus {
									text-decoration: none;
								}
							`}
						>
							{localize({ CZECH: 'Po', ENGLISH: 'Mon' })}&thinsp;-&thinsp;
							{localize({ CZECH: 'Pá', ENGLISH: 'Fri' })}
							&ensp;9:00&thinsp;-&thinsp;15:00
						</HrefLink>
					</Box>
				</Flex>
				<Flex
					css={(theme: Theme) => css`
						font-transform: uppercase;
						display: none;
						@media (max-width: ${theme.breakpoints[3]}) {
							display: flex;
						}
					`}
					justifyContent="flex-end"
					minWidth="80px"
					fontSize={18}
				>
					<Link to="/public/contact">
						{localize({ CZECH: 'Kontakt', ENGLISH: 'Contact' })}
					</Link>
				</Flex>
			</ResponsivePadder>
		</Wrapper>
	);
};
export default ZlinHeader;
