import { JchUser } from 'api';
import { UniVariant } from 'theme';

import { Backend } from 'api/endpoints';

import { EASMultilanguage } from './EASTypes';
import MakeTuple from './MakeTuple';

export const COMPANY_SIZES: Backend.CompanySize[] = [
	'START_UP',
	'MICRO',
	'SMALL',
	'MEDIUM',
	'LARGE',
];

export const CompanySizeTextPrimary: Record<Backend.CompanySize, string> = {
	START_UP: '',
	MICRO: '1 - 9',
	SMALL: '10 - 49',
	MEDIUM: '50 - 249',
	LARGE: '250+',
};

export const CompanySizeTextSecondary: Record<
	Backend.CompanySize,
	EASMultilanguage
> = {
	START_UP: {
		ENGLISH: 'Start-up',
		CZECH: 'Start-up',
	} as EASMultilanguage,
	MICRO: {
		ENGLISH: 'Micro-entreprise',
		CZECH: 'Mikro firma',
	} as EASMultilanguage,
	SMALL: {
		ENGLISH: 'Small enterprise',
		CZECH: 'Malá firma',
	} as EASMultilanguage,
	MEDIUM: {
		ENGLISH: 'Medium-sized enterprise',
		CZECH: 'Střední firma',
	} as EASMultilanguage,
	LARGE: {
		ENGLISH: 'Large enterprise',
		CZECH: 'Velká firma',
	} as EASMultilanguage,
};

export const OWNERSHIP_SECTORS: Backend.OwnershipSector[] = [
	'PUBLIC',
	'NONPROFIT',
	'PRIVATE',
];

export const OwnershipSectorName: Record<
	Backend.OwnershipSector,
	EASMultilanguage
> = {
	PUBLIC: {
		ENGLISH: 'Public',
		CZECH: 'Veřejný',
	} as EASMultilanguage,
	PRIVATE: {
		ENGLISH: 'Private',
		CZECH: 'Soukromý',
	} as EASMultilanguage,
	NONPROFIT: {
		ENGLISH: 'Nonprofit',
		CZECH: 'Neziskový',
	} as EASMultilanguage,
};

export const ORDER_STATE_TYPES: Backend.CompanyOrderState[] = [
	'CREATED',
	'WAITING',
	'REJECT',
	'CONFIRMED',
];

export const OrderStateTextPrimary: Record<
	Backend.CompanyOrderState,
	EASMultilanguage
> = {
	CREATED: {
		ENGLISH: 'Created',
		CZECH: 'Vytvořená',
	} as EASMultilanguage,
	WAITING: {
		ENGLISH: 'Waiting',
		CZECH: 'Čekající',
	} as EASMultilanguage,
	REJECT: {
		ENGLISH: 'Rejected',
		CZECH: 'Zamítnuta',
	} as EASMultilanguage,
	CONFIRMED: {
		ENGLISH: 'Confirmed',
		CZECH: 'Potvrzená',
	} as EASMultilanguage,
};

export const OrderPaymentStateTextPrimary: Record<string, EASMultilanguage> = {
	PAID: {
		CZECH: 'zaplacena',
		ENGLISH: 'paid',
	} as EASMultilanguage,
	PENDING: {
		CZECH: 'nezaplacena',
		ENGLISH: 'unpaid',
	} as EASMultilanguage,
	CANCELED: {
		CZECH: 'platba zrušena',
		ENGLISH: 'payment cancelled',
	} as EASMultilanguage,
};

export const OrderPaymentStateTextSeconadry: Record<
	string,
	EASMultilanguage
> = {
	PENDING: {
		CZECH: 'čeká se na platbu',
		ENGLISH: 'waiting for payment',
	} as EASMultilanguage,
	PAID: {
		CZECH: 'zaplacena',
		ENGLISH: 'paid',
	} as EASMultilanguage,
	CANCELED: {
		CZECH: 'platba zrušena',
		ENGLISH: 'payment cancelled',
	} as EASMultilanguage,
};

export const OrderStateTextSecondary: Record<
	Backend.CompanyOrderState,
	EASMultilanguage
> = {
	CREATED: {
		ENGLISH: 'Waiting for invoice scan',
		CZECH: 'Čeká na sken faktury',
	} as EASMultilanguage,
	WAITING: {
		ENGLISH: 'Waiting for confirmation from administrator',
		CZECH: 'Čeká na potvrzení správcem',
	} as EASMultilanguage,
	REJECT: {
		ENGLISH: 'Rejected by administrator',
		CZECH: 'Zamítnuta správcem',
	} as EASMultilanguage,
	CONFIRMED: {
		ENGLISH: 'Confirmed by administrator',
		CZECH: 'Potvrzena správcem',
	} as EASMultilanguage,
};

export const EmploymentTypeText: Record<
	Backend.EmploymentType,
	EASMultilanguage
> = {
	BP: {
		ENGLISH: `Bachelor's thesis`,
		CZECH: 'Bakalářská práce',
	} as EASMultilanguage,
	DP: {
		ENGLISH: `Master's Thesis`,
		CZECH: 'Diplomová práce',
	} as EASMultilanguage,
	DPP_DPC: {
		ENGLISH: 'Work contract',
		CZECH: 'Dohoda o provedení práce / pracovní činnosti',
	} as EASMultilanguage,
	TRAINEE: {
		ENGLISH: 'Traineeship',
		CZECH: 'Trainee program',
	} as EASMultilanguage,
	SHORT: {
		ENGLISH: 'Part-time',
		CZECH: 'Částečný úvazek',
	} as EASMultilanguage,
	HPP: {
		ENGLISH: 'Full-time',
		CZECH: 'Hlavní pracovní poměr',
	} as EASMultilanguage,
	VOLUNTEER: {
		ENGLISH: 'Volunteer',
		CZECH: 'Dobrovolnictví',
	} as EASMultilanguage,
	OTHER: {
		ENGLISH: 'Other',
		CZECH: 'Jiný typ spolupráce',
	} as EASMultilanguage,
	OSVC: {
		ENGLISH: 'Self-employed',
		CZECH: 'OSVČ',
	} as EASMultilanguage,
	INTERNSHIP: {
		ENGLISH: 'Professional experience / Internship',
		CZECH: 'Odborná praxe / stáž',
	} as EASMultilanguage,
};

export const GRADUATION_THESES_TYPES: Backend.GraduationThesesType[] = [
	'BC',
	'MGR',
	'PHD',
	'RNDR',
];

export const GraduationThesesTypeText: Record<
	Backend.GraduationThesesType,
	EASMultilanguage
> = {
	BC: {
		ENGLISH: `Bachelor's thesis`,
		CZECH: 'Bakalářská práce',
	} as EASMultilanguage,
	MGR: {
		ENGLISH: `Master's Thesis`,
		CZECH: 'Diplomová práce',
	} as EASMultilanguage,
	PHD: {
		ENGLISH: `Dissertation Thesis`,
		CZECH: 'Disertační práce',
	} as EASMultilanguage,
	RNDR: {
		ENGLISH: `Rigorous Thesis`,
		CZECH: 'Rigorózní práce',
	} as EASMultilanguage,
};

export const HIGHEST_EDUCATION_OPTIONS = MakeTuple('Bc.', 'Mgr.', 'PhD.');

export type HIGHEST_EDUCATION_TYPE = typeof HIGHEST_EDUCATION_OPTIONS[number];

export const HIGHEST_EDUCATION_TEXT: Record<
	HIGHEST_EDUCATION_TYPE,
	EASMultilanguage
> = {
	'Bc.': {
		ENGLISH: `Bc.`,
		CZECH: 'Bc.',
	} as EASMultilanguage,
	'Mgr.': {
		ENGLISH: `Mgr. / Ing.`,
		CZECH: 'Mgr. / Ing.',
	} as EASMultilanguage,
	'PhD.': {
		ENGLISH: `PhD.`,
		CZECH: 'PhD.',
	} as EASMultilanguage,
};

export type PERSON_TYPE_SEARCH_OPTIONS = Extract<
	Backend.CommonPersonType,
	'STUDENT' | 'ALUMNUS'
>;

export const PERSON_TYPE_TEXT: Record<
	PERSON_TYPE_SEARCH_OPTIONS,
	EASMultilanguage
> = {
	STUDENT: {
		ENGLISH: `Students only`,
		CZECH: 'Pouze studenti',
	} as EASMultilanguage,
	ALUMNUS: {
		ENGLISH: `Alumni only`,
		CZECH: 'Pouze absolventi',
	} as EASMultilanguage,
};

export const STUDENT_REVEALED_TYPE_TEXT: Record<
	Backend.StudentRevealedType,
	EASMultilanguage
> = {
	REVEALED: {
		ENGLISH: `Revealed only`,
		CZECH: 'Pouze odkrytí',
	} as EASMultilanguage,
	UNREVEALED: {
		ENGLISH: `Unrevealed only`,
		CZECH: 'Pouze neodkrytí',
	} as EASMultilanguage,
};

export const SEMESTERS_OPTIONS = MakeTuple('jaro', 'podzim');
export type SEMESTER_OPTIONS_TYPE = typeof SEMESTERS_OPTIONS[number];

export const SemesterOptionsText: Record<
	SEMESTER_OPTIONS_TYPE,
	EASMultilanguage
> = {
	jaro: {
		ENGLISH: `Spring semester`,
		CZECH: 'Jarní semestr',
	} as EASMultilanguage,
	podzim: {
		ENGLISH: `Autumn semester`,
		CZECH: 'Podzimní semestr',
	} as EASMultilanguage,
};

export const SemesterOptionsTextZLIN: Record<
	SEMESTER_OPTIONS_TYPE,
	EASMultilanguage
> = {
	jaro: {
		ENGLISH: `Summer semester`,
		CZECH: 'Letní semestr',
	} as EASMultilanguage,
	podzim: {
		ENGLISH: `Winter semester`,
		CZECH: 'Zimní semestr',
	} as EASMultilanguage,
};

export const LANGUAGE_LEVELS: Backend.LanguageLevel[] = [
	'A1',
	'A2',
	'B1',
	'B2',
	'C1',
	'C2',
];

export const LanguageLevelDescriptionTitle: Record<
	Backend.LanguageLevel,
	EASMultilanguage
> = {
	A1: {
		ENGLISH: 'Weaker passive knowledge of a foreign language',
		CZECH: 'Slabší pasivní znalost cizího jazyka',
	} as EASMultilanguage,
	A2: {
		ENGLISH: 'Passive knowledge of a foreign language',
		CZECH: 'Pasivní znalost cizího jazyka',
	} as EASMultilanguage,
	B1: {
		ENGLISH: 'Knowledge of a foreign language at a communicative level',
		CZECH: 'Znalost cizího jazyka na komunikativní úrovni',
	} as EASMultilanguage,
	B2: {
		ENGLISH: 'Knowledge of a foreign language at a good communicative level',
		CZECH: 'Znalost cizího jazyka na dobré komunikativní úrovni',
	} as EASMultilanguage,
	C1: {
		ENGLISH: 'Very good knowledge of a foreign language',
		CZECH: 'Velmi dobrá znalost cizího jazyka',
	} as EASMultilanguage,
	C2: {
		ENGLISH: 'Excellent knowledge of a foreign language',
		CZECH: 'Výborná znalost cizího jazyka',
	} as EASMultilanguage,
};

export const LanguageLevelDescription: Record<
	Backend.LanguageLevel,
	EASMultilanguage
> = {
	A1: {
		ENGLISH:
			'Level of beginners who understand sentences and words related to their person and their surroundings. They are only able to have a very simple and straightforward conversation with a speaker who has patience with them. They can only express basic ideas about their person through phrases they know from studying. At this level, the student is able to write a short, simple text on a postcard, such as a holiday greeting. Can fill in forms containing basic personal data.',
		CZECH:
			'Úroveň začátečníků, kteří rozumí větám a slovům týkajícím se jejich osoby a jejich okolí. Jsou schopni vést jen velmi jednoduchou a prostou konverzaci s mluvčím, který s nimi má trpělivost. Sami dokážou vyjádřit pouze základní myšlenky o své osobě slovními spojeními, která znají ze studia. Student na této úrovni dokáže napsat stručný jednoduchý text na pohlednici, například pozdrav z dovolené. Umí vyplnit formuláře obsahující základní osobní údaje.',
	} as EASMultilanguage,
	A2: {
		ENGLISH: `Can understand phrases and more common vocabulary related to areas of most immediate relevance (eg very basic personal and family information, leisure, shopping, topography, employment). Can read short, simple texts and look for specific predictable information. They orient themselves in simple everyday materials, such as advertisements, brochures, menus and timetables.
		Can communicate in simple, often recurring situations with a predictable exchange of information on familiar topics and activities. Can handle a short social conversation and write a simple personal letter.`,
		CZECH: `Rozumí frázím a běžnější slovní zásobě vztahující se k oblastem, které se ho bezprostředně týkají (např. základní informace o jeho rodině a přátelích, o jeho volném čase, nakupování, místopisu, zaměstnání). Umí číst krátké jednoduché texty a v nich vyhledat konkrétní předvídatelné informace. Orientuje se v jednoduchých každodenních materiálech, např. v inzerátech, prospektech, jídelních lístcích a jízdních řádech.
		Dokáže komunikovat v jednoduchých, často se opakujících situacích s předvídatelnou výměnou informací o známých tématech a činnostech. Zvládne krátkou společenskou konverzaci a napsat jednoduchý osobní dopis.`,
	} as EASMultilanguage,
	B1: {
		ENGLISH: `Can also understand longer modified texts that contain vocabulary often used in everyday life or that relate to their work and the world around them. Is able to name most basic things and feelings in everyday life on a relatively comprehensive basis, including basic abstract expressions.
		Can deal with most situations likely to arise whilst traveling. Is able to engage in conversation without preparation on topics that are familiar to them, that they are interested in or that relate to everyday life. Can tell a story or outline the content of a book or film and describe their reactions, describe experiences and events, dreams, hopes and desires, and briefly justify and explain their views and plans.
		Is able to create a simple coherent, logically structured text on topics that they know well or that is of personal interest, and functionally use the means of textual continuity.`,
		CZECH: `Rozumí i delším upraveným textům, které obsahují slovní zásobu často užívanou v každodenním životě nebo které  se vztahují k jeho práci a světu okolo. Je schopen na poměrně ucelené bázi pojmenovat většinu základních věcí a pocitů v každodenním životě, to včetně základních abstraktních výrazů.
		Umí si poradit s většinou situací, které mohou nastat při cestování. Dokáže se bez přípravy zapojit do hovoru o tématech, která jsou mu známá, o něž se zajímá nebo která se týkají každodenního života. Dokáže vyprávět příběh nebo v hrubých rysech přiblížit obsah knihy či filmu a vylíčit své reakce, popsat zážitky a události, sny, naděje a touhy a stručně zdůvodnit a vysvětlit své názory a plány.
		Umí vytvořit jednoduchý souvislý, logicky strukturovaný text o tématech, která dobře zná nebo která ho osobně zajímají, a funkčně využívat prostředky textové návaznosti.`,
	} as EASMultilanguage,
	B2: {
		ENGLISH: `Understands the main ideas of authentic texts with concrete and abstract topics, including a professional discussion of the field of his specialization. Can follow a longer lecture, basically understands simpler films and series in the original version, with a basic understanding can read texts of contemporary prose.
		Can communicate fluently and spontaneously with native speakers, without much effort on both sides.
		Can compile a more detailed, comprehensible text on a relatively wide range of topics, explain and defend the position on current issues, justify the advantages and disadvantages of various options.`,
		CZECH: `Rozumí hlavním myšlenkám autentických textů s konkrétními i abstraktními náměty, včetně odborné diskuse o oboru své specializace. Dokáže sledovat delší přednášku, v zásadě rozumí jednodušším filmům a seriálům v originálním znění, se základním porozuměním dokáže číst texty současné prózy.
		Dokáže se dorozumět plynule a spontánně s rodilými mluvčími, a to bez většího úsilí na obou stranách.
		Umí sestavit podrobnější, srozumitelný text o poměrně širokém okruhu témat, vysvětlit a obhájit stanovisko k aktuálním problémům, zdůvodnit výhody a nevýhody různých možností.`,
	} as EASMultilanguage,
	C1: {
		ENGLISH: `An experienced user who understands a wide range of demanding longer texts, even in the field of fiction. Can recognize implicit meanings in text ("read between lines") and appreciate the style of the author. They express themselves fluently and spontaneously, without too obvious search for expressions, and they are able to use the language effectively for social, study and work purposes.
		Can create a clear, well-constructed, detailed text on complex topics, consciously using the principles of text construction, conjunctions and means of expressing links in the text.`,
		CZECH: `Zkušený uživatel, který rozumí širokému okruhu náročných delších textů, a to i z oblasti beletrie. Dokáže rozpoznat implicitní významy v textu ("číst mezi řádky") a ocenit styl daného autora. Vyjadřuje se plynule a spontánně, bez příliš zjevného hledání výrazů, a dokáže jazyk účinně používat pro společenské, studijní i pracovní účely.
		Umí vytvořit jasný, dobře kompozičně vystavěný, podrobný text o složitých tématech, uvědoměle používat zásady výstavby textu, spojovací výrazy a prostředky vyjadřující vazby v textu.`,
	} as EASMultilanguage,
	C2: {
		ENGLISH: `Professional user, easily understands virtually everything they hear or read. They can express themselves completely spontaneously, very fluently and accurately, while distinguishing finer shades of meaning even in more complex situations. Can create complex, precisely stylistically and ideologically structured texts, present them continuously and lead a controversy about them.`,
		CZECH: `Profesionální uživatel, snadno rozumí prakticky všemu, co slyší nebo čte. Umí se vyjadřovat zcela spontánně, velice plynule a přesně, přičemž rozlišuje jemnější významové odstíny i ve složitějších situacích. Dokáže vytvořit složité, precizně slohově i myšlenkově strukturované texty, souvisle je prezentovat a vést o nich polemiku.`,
	} as EASMultilanguage,
};

export const FORM_LABELS: Backend.FormLabel[] = [
	'CONTACTED',
	'REJECTED',
	'CONTACT_LATER',
];

export const FormLabelText: Record<Backend.FormLabel, EASMultilanguage> = {
	CONTACTED: {
		ENGLISH: 'Contacted',
		CZECH: 'Kontaktován',
	} as EASMultilanguage,
	REJECTED: {
		ENGLISH: 'Rejected',
		CZECH: 'Zamítnut',
	} as EASMultilanguage,
	CONTACT_LATER: {
		ENGLISH: 'Contact later',
		CZECH: 'Kontaktovat později',
	} as EASMultilanguage,
};

export const NotificationFrequencyList: Backend.NotificationFrequencyType[] = MakeTuple(
	'IMMEDIATELY',
	'DAILY',
	'WEEKLY',
);

export const NotificationFrequencyTypeText: Record<
	Backend.NotificationFrequencyType,
	EASMultilanguage
> = {
	IMMEDIATELY: {
		ENGLISH: 'Immediately',
		CZECH: 'Okamžitě',
	} as EASMultilanguage,
	DAILY: {
		ENGLISH: 'Daily',
		CZECH: 'Denně',
	} as EASMultilanguage,
	WEEKLY: {
		ENGLISH: 'Weekly',
		CZECH: 'Týdně',
	} as EASMultilanguage,
};

export const NotificationColors: Record<
	Backend.NotificationTemplateType,
	string
> = {
	NEW_RESPONSE_TO_OFFER: 'primary',
	ACTIVATED_COMPANY: 'primary',
	REGISTERED_COMPANY_ADMIN: 'secondary',
	REGISTERED_COMPANY: 'secondary',
	REGISTERED_ALUMNUS: 'secondary',
	ACCEPTED_ORDER: 'primary',
	REJECTED_ORDER: 'error',
	UNPAID_ORDER: 'error',
	RECEIVED_NEW_ORDER: 'primary',
	ENDING_ACTIVE_LICENSE_IN_ONE_MONTH: 'warning',
	ENDING_ACTIVE_LICENSE_IN_TWO_WEEKS: 'warning',
	ENDING_ACTIVE_LICENSE_IN_ONE_WEEK: 'warning',
	ENDING_ACTIVE_LICENSE_IN_TWO_WEEKS_ADMIN: 'warning',
	ENDING_ACCESS: 'error',
	DEPLETING_REVEALS: 'warning',
	ENDING_OFFER_IN_ONE_WEEK: 'warning',
	ENDING_OFFER_TOMORROW: 'warning',
	DEACTIVATED_OFFER: 'error',
	NEW_OFFER_FOLLOWED_COMPANY: 'primary',
	OFFER_HARDSKILL_REMOVED: 'error',
	SUBMITTED_FORM_WAS_READ_BY_COMPANY: 'primary',
	NEW_SUGGESTED_HARDSKILL: 'primary',
	STUDENT_SUGGESTED_HARDSKILL_ACCEPTED: 'primary',
	STUDENT_SUGGESTED_HARDSKILL_REJECTED: 'error',
	STUDENT_HARDSKILL_REMOVED: 'error',
	COMPANY_SUGGESTED_HARDSKILL_ACCEPTED: 'primary',
	COMPANY_SUGGESTED_HARDSKILL_REJECTED: 'error',
	CUSTOM_CONTENT: 'secondary',
	RESET_PASSWORD: 'warning',
	COMPANY_TOO_IDLE: 'error',
	// TODO: Implement new notification types
	ALUMNUS_ACCESS_ENDED: 'error',
	COMPANY_EXTERNAL_OFFER_ACTIVATED: 'primary',
	ENDING_ALUMNUS_ACCESS_IN_ONE_MONTH: 'warning',
	ENDING_ALUMNUS_ACCESS_IN_ONE_WEEK: 'warning',
	STUDENT_REVEALED_BY_COMPANY: 'primary',
	DAILY_NEW_OFFERS_FOLLOWED_COMPANIES: 'primary',
	WEEKLY_NEW_OFFERS_FOLLOWED_COMPANIES: 'primary',
};

export const LocalizationToLanguage: Record<string, Backend.eas.Language> = {
	cz: 'CZECH',
	en: 'ENGLISH',
	de: 'GERMAN',
	sk: 'SLOVAK',
};

export const LocaleFromLocalization: Record<string, string> = {
	cz: 'cs',
	en: 'en',
	de: 'de',
	sk: 'sk',
};

export const LangTagFromLocalization: Record<string, string> = {
	cz: 'cs-CZ',
	en: 'en-US',
	de: 'de-DE',
	sk: 'sk-SK',
};

export const PrefixFromPersonType: Record<
	JchUser['personType'] | 'PUBLIC',
	string
> = {
	ADMIN: '/admin',
	ALUMNUS: '/student',
	STUDENT: '/student',
	RECRUITER: '/company',
	PUBLIC: '/public',
};

export const MonthsLabels = [
	{ CZECH: 'Leden', ENGLISH: 'January' },
	{ CZECH: 'Únor', ENGLISH: 'February' },
	{ CZECH: 'Březen', ENGLISH: 'March' },
	{ CZECH: 'Duben', ENGLISH: 'April' },
	{ CZECH: 'Květen', ENGLISH: 'May' },
	{ CZECH: 'Červen', ENGLISH: 'June' },
	{ CZECH: 'Červenec', ENGLISH: 'July' },
	{ CZECH: 'Srpen', ENGLISH: 'August' },
	{ CZECH: 'Září', ENGLISH: 'September' },
	{ CZECH: 'Říjen', ENGLISH: 'October' },
	{ CZECH: 'Listopad', ENGLISH: 'November' },
	{ CZECH: 'Prosinec', ENGLISH: 'December' },
];

export const BeUniAbbrev: Record<UniVariant, Backend.University> = {
	MUNI: 'MUNI',
	PLZEN: 'ZCU',
	LIBEREC: 'TUL',
	ZLIN: 'UTB',
};

export const UniAbbrev: Record<UniVariant, string> = {
	MUNI: 'MUNI',
	PLZEN: 'ZČU',
	LIBEREC: 'TUL',
	ZLIN: 'UTB',
};

export const UniStag: Record<UniVariant, string> = {
	MUNI: 'STAG',
	LIBEREC: 'LIANE',
	PLZEN: 'Orion login',
	ZLIN: 'STAG',
};

export const MotivationalAnchorTitle: Record<
	Backend.MotivationalAnchor,
	EASMultilanguage
> = {
	DECISION_POWER_AND_LEADERSHIP: {
		CZECH: 'Rozhodovací pravomoci a vedení druhých',
		ENGLISH: 'Decision power and leadership',
	},
	EXPERTISE: { CZECH: 'Odbornost', ENGLISH: 'Expertise' },
	JOB_SECURITY: { CZECH: 'Jistá / stabilní pozice', ENGLISH: 'Job security' },
	MEANINGFUL_WORK: { CZECH: 'Smysluplná práce', ENGLISH: 'Meaningful work' },
	PRAISING_AND_VALUING_YOUR_WORK: {
		CZECH: 'Pochvala a ocenení za práci',
		ENGLISH: 'Praising and valuing your work',
	},
	SALARY_AND_BENEFITS: {
		CZECH: 'Dobrý plat a benefity',
		ENGLISH: 'Salary and benefits',
	},
	SOCIAL_STATUS: {
		CZECH: 'Dobré společenské postavení',
		ENGLISH: 'Social status',
	},
	TEAM: { CZECH: 'Dobrý kolektiv', ENGLISH: 'Team' },
	WORK_AUTONOMY: { CZECH: 'Samostatnost při práci', ENGLISH: 'Work autonomy' },
};

export const MotivationalAnchorsList = MakeTuple(
	'WORK_AUTONOMY',
	'TEAM',
	'SOCIAL_STATUS',
	'SALARY_AND_BENEFITS',
	'DECISION_POWER_AND_LEADERSHIP',
	'EXPERTISE',
	'JOB_SECURITY',
	'PRAISING_AND_VALUING_YOUR_WORK',
	'MEANINGFUL_WORK',
);
//databázi
export const ALUMNUS_REG_MESSAGE: Record<string, EASMultilanguage> = {
	'Alumnus does not exist in stag': {
		CZECH:
			'Absolvent neexistuje v systému {{stag}}. Zkontrolujte, zda je Vaše přihlašovací jméno ({{stag}}) vyplněno správně.',
		ENGLISH:
			'Alumnus does not exist in {{stag}}. Check if your {{stag}} username is correct.',
	},

	'Alumnus email is not same as alumnus club': {
		CZECH:
			'Email absolventa se neshoduje s emailem v absolventském klubu. Zkontrolujte, zda je email vyplněn správně.',
		ENGLISH:
			'Alumnus email is not the same as in alumnus club.  Check if your email is correct.',
	},

	'Alumnus with username already exists': {
		ENGLISH: 'This username is already registered.',
		CZECH: 'Uživatelské jméno je již zaregistrováno.',
	},
	default: {
		ENGLISH: 'Check if your {{stag}} username or email is correct.',
		CZECH:
			'Zkontrolujte, zda je přihlašovací jméno ({{stag}}) nebo email správně.',
	},
};

export const KARIERKA_PROFIL_ID = '710f76bb-c31a-4b37-bb2b-44a861dfef22';

export const ProductsTypesTuple = MakeTuple(
	'LICENSE_FREE',
	'LICENSE_ADVERT',
	'LICENSE_DATABASE',
	'LICENSE_COMPLETE',
	'LICENSE_PREMIUM',
	'OFFERS_LIMITED',
	'REVEALS_LIMITED',
	'OFFERS_UNLIMITED',
	'REVEALS_UNLIMITED',
);

export type ProductTypeById = typeof ProductsTypesTuple[number];

export type LicenseTypeById = Extract<
	ProductTypeById,
	| 'LICENSE_FREE'
	| 'LICENSE_DATABASE'
	| 'LICENSE_ADVERT'
	| 'LICENSE_COMPLETE'
	| 'LICENSE_PREMIUM'
>;

export type RevealsTypeById = Extract<
	ProductTypeById,
	'REVEALS_LIMITED' | 'REVEALS_UNLIMITED'
>;

export type OffersTypeById = Extract<
	ProductTypeById,
	'OFFERS_LIMITED' | 'OFFERS_UNLIMITED'
>;

export const MapProductIdMuni: Record<string, ProductTypeById> = {
	'e1d74a04-b032-48bd-8857-c964872f22a7': 'LICENSE_PREMIUM',
	'3449e033-f925-40ac-95bb-2a83dd63927a': 'LICENSE_FREE', // BASIC
	//'62f070e0-2ced-41dc-a066-629f8a317401': 'LICENSE_FREE', // BASIC - LOCAL
	'6a5982be-073c-4c78-bae4-ed90411c135e': 'LICENSE_ADVERT',
	'ff051467-253c-4198-a6cd-fb3c3ca4faca': 'LICENSE_DATABASE',
	'62eaaff9-86a1-44d3-943f-95216ebb7c4a': 'LICENSE_COMPLETE',
	'04a34857-ffe5-498b-8e93-c98435540044': 'OFFERS_LIMITED',
	'4964b4e4-f12d-4322-b75a-44ca60e858fc': 'OFFERS_UNLIMITED',
	'320f85b6-cae1-49be-9872-503e75bd5d05': 'REVEALS_LIMITED',
	'd042932e-2219-4a72-938b-cde67e102732': 'REVEALS_UNLIMITED',
};
export const MapProductIdPlzen: Record<string, ProductTypeById> = {
	'62f070e0-2ced-41dc-a066-629f8a317401': 'LICENSE_FREE',
	'6a5982be-073c-4c78-bae4-ed90411c135e': 'LICENSE_ADVERT',
	'ff051467-253c-4198-a6cd-fb3c3ca4faca': 'LICENSE_DATABASE',
	'62eaaff9-86a1-44d3-943f-95216ebb7c4a': 'LICENSE_COMPLETE',
	'04a34857-ffe5-498b-8e93-c98435540044': 'OFFERS_LIMITED',
	'4964b4e4-f12d-4322-b75a-44ca60e858fc': 'OFFERS_UNLIMITED',
	'320f85b6-cae1-49be-9872-503e75bd5d05': 'REVEALS_LIMITED',
};
export const MapProductIdLiberec: Record<string, ProductTypeById> = {
	'62f070e0-2ced-41dc-a066-629f8a317401': 'LICENSE_FREE',
	'6a5982be-073c-4c78-bae4-ed90411c135e': 'LICENSE_ADVERT',
	'62eaaff9-86a1-44d3-943f-95216ebb7c4a': 'LICENSE_DATABASE',
	'ff051467-253c-4198-a6cd-fb3c3ca4faca': 'LICENSE_COMPLETE',
	'04a34857-ffe5-498b-8e93-c98435540044': 'OFFERS_LIMITED',
	'4964b4e4-f12d-4322-b75a-44ca60e858fc': 'OFFERS_UNLIMITED',
	'320f85b6-cae1-49be-9872-503e75bd5d05': 'REVEALS_LIMITED',
};
export const MapProductIdZlin: Record<string, ProductTypeById> = {
	'62f070e0-2ced-41dc-a066-629f8a317401': 'LICENSE_FREE',
	'6a5982be-073c-4c78-bae4-ed90411c135e': 'LICENSE_ADVERT',
	'ff051467-253c-4198-a6cd-fb3c3ca4faca': 'LICENSE_DATABASE',
	'62eaaff9-86a1-44d3-943f-95216ebb7c4a': 'LICENSE_COMPLETE',
	'04a34857-ffe5-498b-8e93-c98435540044': 'OFFERS_LIMITED',
	'4964b4e4-f12d-4322-b75a-44ca60e858fc': 'OFFERS_UNLIMITED',
	'320f85b6-cae1-49be-9872-503e75bd5d05': 'REVEALS_LIMITED',
	'd042932e-2219-4a72-938b-cde67e102732': 'REVEALS_UNLIMITED',
};

export const MapRelevantProductsToLicensesZlin: Record<
	LicenseTypeById,
	(OffersTypeById | RevealsTypeById)[]
> = {
	LICENSE_FREE: ['OFFERS_LIMITED', 'REVEALS_LIMITED'],
	LICENSE_ADVERT: ['REVEALS_LIMITED', 'OFFERS_UNLIMITED'],
	LICENSE_DATABASE: ['OFFERS_LIMITED', 'REVEALS_UNLIMITED'],
	LICENSE_COMPLETE: [],
	LICENSE_PREMIUM: [],
};
